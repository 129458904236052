import { GET_EVENEMENT, DELETE_EVENEMENT, ADD_EVENEMENT } from "../actions/EvenementAction";

const initiatState = {}

export default function evenementReducer(state = initiatState, action) {
    switch (action.type) {
        case GET_EVENEMENT:
            return action.payload
        case ADD_EVENEMENT:
            return action.payload
        case DELETE_EVENEMENT:
            return state.filter((postMembre) => postMembre.id != action.payload);
        default:
            return state
    }
}