
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteVideo } from '../../actions/videoAction';

function AfficheVideo() {
    const video = useSelector((state) => state.videoReducer);
    const dispatch = useDispatch();

    return (
        <div class="row">
            {
                Array.isArray(video) && video.map((sd) => {
                    return (
                        <div class="col-lg-6">
                            <div class="thumb after-left-tops">
                                <iframe width="460" height="315" src={sd.lien} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                <p>
                                    <p>{sd.titre}</p>
                                    <button  className='btn btn-danger' onClick={dispatch(deleteVideo(sd.id))}> 
                                        <i className='fa fa-trash '></i>
                                    </button>
                                    
                                </p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AfficheVideo