import React from 'react';

const Loading = () => {
    return (
        <>
           <center><i style={{fontSize : 30, color : 'white'}} class="fa-solid fa-circle-notch fa-rotate-270 fa-xl fa-pulse text-success"></i></center>
        </>
    );
};

export default Loading;