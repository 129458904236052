import axios from "axios";
import Token from "../loadingErr/Token";
import Swal from "sweetalert2";


export const GET_ECRAN_TEXT = "GET_ECRAN_TEXT";
export const DELETE_TEXT_ECRAN = "DELETE_TEXT_ECRAN";
export const ADD_TEXT_ECRAN = "ADD_TEXT_ECRAN";
export const GET_TEXT_ONE = "GET_TEXT_ONE";

const url = "https://apieglise.loginsmart-cd.com/api/";



export const getText = () => {
    return (dispatch) => {
        return axios.get(`${url}sousmenus`, { 
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: Token()
            }
        }).then((response) => {
            dispatch({ type: GET_ECRAN_TEXT, payload: response.data.data })
        }).catch((error) => {
            alert(error + 'text')
        })
    }
}

export const getTextOne = () => {
    return (dispatch) => {
        return axios.get(`${url}text`, { 
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: Token()
            }
        }).then((response) => {
            dispatch({ type: GET_TEXT_ONE, payload: response.data.data })
        }).catch((error) => {
            alert(error + 'text')
        })
    }
}

export const deleteText = (id) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Êtes-vous sûr de vouloir supprimer cet text ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer',
            cancelButtonText: 'Non, annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${url}sousmenu/${id}`, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: Token()
                        }
                    })
                    .then((response) => {
                        dispatch({ type: DELETE_TEXT_ECRAN, payload: id });
                        Swal.fire({
                            icon: 'success',
                            text: `${response.data.message}`,
                            confirmButtonText: 'OK'
                        });
                    })
                    .catch((error) => {
                        alert(error);
                    });
            }
        });
    };
};

export const addText = (postData) => {
    return (dispatch) => {
        return axios.post(`${url}sousmenu`, postData,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: Token()
                }
            }).then((response) => {
                dispatch({ type: ADD_TEXT_ECRAN, payload: postData })
                Swal.fire({
                    icon: 'success',
                    text: `${response.data.message}`,
                    confirmButtonText: 'OK'
                })
            }).catch((error) => {

                if (error.response && error.response.status === 422) {
                    Swal.fire({
                        icon: 'error',
                        text: `Tous les champs sont obligatoiress !`,
                    });
                } else if (error.response.status === 500) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erreur de la connexion !!!',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: `${error.response.data.message}`,
                        confirmButtonText: 'OK'
                    })
                }
            })
    }
}

