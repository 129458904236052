import { GET_ECRAN_TEXT, ADD_TEXT_ECRAN, DELETE_TEXT_ECRAN, GET_TEXT_ONE } from "../actions/TextEcranAction";

const initiatState = {}

export default function textEcranReducer(state = initiatState, action) {
    switch (action.type) {
        case GET_ECRAN_TEXT:
            return action.payload
        case GET_TEXT_ONE:
            return action.payload
        case ADD_TEXT_ECRAN:
            return action.payload
        case DELETE_TEXT_ECRAN:
            return state.filter((postMembre) => postMembre.id != action.payload);
        default:
            return state
    }
}