import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addEvenement, getEvenement } from '../../actions/EvenementAction';

const AddEvenement = () => {
    const form = useRef();
    const dispatch = useDispatch();

    const handleSave = async (e) => {
        e.preventDefault();

        const postData = {
            titre: form.current[0].value,
            image: imageBase64,
            date: form.current[1].value,
            text: form.current[3].value
        }
        await dispatch(addEvenement(postData));
        dispatch(getEvenement())
        form.current.reset();
    }

    const [imageBase64, setImageBase64] = useState('');
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            setImageBase64(base64String);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    return (
        <div class="card flex-fill">
            <div class="card-header">
                <h1 class=" text-center">Ajouter un Evenemnt</h1>
            </div>
            <hr />
            <div>
                {imageBase64 && (
                    <img src={imageBase64} alt="Uploaded" style={{ maxWidth: '200px' }} />
                )}
              

            </div>
            <div className="row">
                <form ref={form} onSubmit={(e) => handleSave(e)}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">Titre evenement</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">Date debut</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">Image de l'evenement</label>
                            <input className="form-control" type="file" accept="image/*" onChange={handleImageUpload} />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="">Text de l'evenement</label>
                            <textarea name="" className='form-control' id="" cols="30" rows="10"></textarea>
                        </div>
                        <div className="col-md-12">
                            <br />
                            <button type="submit" className="btn btn-primary">Envoyer</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddEvenement