import React from 'react';
import { useSelector } from 'react-redux';

const EcranUser = () => {
  const backgroundImageUrl = 'bible.jpg'; // Remplacez par l'URL de votre image de fond
  const video = useSelector((state) => state.textEcranReducer);
  const renderTextWithFormatting = (html) => {
    return { __html: html };
  };
  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${backgroundImageUrl})`, height: "100vh" }}>
      <div className="carsd text-center">
        
        <div className="card-body">
          <p className="card-tesxt text-white">
            <div className='container'>
            <p style={{fontSize : '50px'}} dangerouslySetInnerHTML={renderTextWithFormatting(video && video.nom_smenu)}></p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EcranUser;