import { GET_VIDEO, ADD_VIDEO, DELETE_VIDEO } from "../actions/videoAction";

const initiatState = {}

export default function videoReducer(state = initiatState, action) {
    switch (action.type) {
        case GET_VIDEO:
            return action.payload
        case ADD_VIDEO:
            return action.payload
        case DELETE_VIDEO:
            return state.filter((postMembre) => postMembre.id != action.payload);
        default:
            return state
    }
}