import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addText, getText, getTextOne } from '../../actions/TextEcranAction';
import { useDispatch } from 'react-redux';

const AddTextEcrant = () => {
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
  
    const SaveText = async () => {
        const postData = {
            nom_smenu: value
        };
        await dispatch(addText(postData));
        dispatch(getTextOne());
        dispatch(getText());
    };

    return (
        <>
           <ReactQuill theme="snow" value={value} onChange={setValue} />
           <br />
           <button onClick={SaveText} className='btn btn-primary'><i className='fa fa-sign-in'></i> Envoyer</button>
           <br />
           <br />
        </>
    );
};

export default AddTextEcrant;