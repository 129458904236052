import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteText } from '../../actions/TextEcranAction';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEcranAffiche = () => {
    const video = useSelector((state) => state.textEcranReducer);
    const dispatch = useDispatch();
    const renderTextWithFormatting = (html) => {
        return { __html: html };
      };
  return (
    <div class="row">
                {
                    Array.isArray(video) && video.map((sd) => {
                        return (
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <p>
                                             <p dangerouslySetInnerHTML={renderTextWithFormatting(sd.nom_smenu)}></p>
                                             <button className='btn btn-danger' onClick={() => dispatch(deleteText(sd.id))}>
                                                <i className='fa fa-trash '></i>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
  )
}

export default TextEcranAffiche