import React, { useRef } from 'react'
import { useDispatch } from 'react-redux';
import { addVideo, getVideo } from '../../actions/videoAction';

function AddVideo() {
  const form  = useRef();
  const dispatch = useDispatch();

  const handleSave = async (e) => {
    e.preventDefault();

    const postData = {
        titre: form.current[0].value,
        lien: form.current[1].value
    }
    await dispatch(addVideo(postData));
    dispatch(getVideo())
    
  }
  return (
    <div class="card flex-fill">
            <div class="card-header">
                <h1 class=" text-center">Ajouter une video</h1>
            </div>
            <hr />
            <div className="row">

                <form ref={form} onSubmit={(e) => handleSave(e)}>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">Titre video</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">lien</label>
                            <input type="text" className="form-control" />
                        </div>
                
                        <div className="col-md-12">
                            <br />
                            <button type="submit" className="btn btn-primary">Envoyer</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
  )
}

export default AddVideo