import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvenement } from '../../actions/EvenementAction';

const AfficheEvenement = () => {
    const video = useSelector((state) => state.evenementReducer);
    const dispatch = useDispatch();
    
    return (
        <>
            <div class="row">
                {
                    Array.isArray(video) && video.map((sd) => {
                        return (
                            <div class="col-lg-4">
                                <div class="card">
                                    <img src={sd.image} alt={sd.image} width="100" height='100' className='card-img-top' />
                                    <div class="card-body">
                                        <p>
                                            <h4 style={{fontWeight : 'bold'}}>{sd.titre}</h4>
                                            <p>{sd.text}</p>
                                            <button className='btn btn-danger' onClick={() => dispatch(deleteEvenement(sd.id))}>
                                                <i className='fa fa-trash '></i>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default AfficheEvenement